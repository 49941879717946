import { getLocalizedUri } from '@/components/i18n/lang-context'
import { siteConfig } from '@/config/site.config'
import { clsx, type ClassValue } from 'clsx'
import { ethers } from 'ethers'
import { Metadata } from 'next'
import { twMerge } from 'tailwind-merge'

// Merge the given tailwind classes
//////////////////////////////////////////////////////////////
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

// Checks if the Environment variable is defined
//////////////////////////////////////////////////////////////
export function hasEnv(key: string): boolean {
  if (isBrowser()) throw 'Only use server-side'
  return !(typeof process.env[key] === undefined || !process.env[key])
}
// Returns the Environment variable and throw an error if not found
//////////////////////////////////////////////////////////////
export function env(key: string): string {
  if (isBrowser()) throw 'Only use server-side'
  if (!hasEnv(key))
    throw new Error(`Missing environment variable: ${key}`)
  return process.env[key] as string
}
// Check if the given value is null or undefined and return it if not
//////////////////////////////////////////////////////////////
export function checkEnv(v: any, key: string): any {
  if (!v) throw new Error(`Missing environment variable: ${key}`)
  return v
}

// Returns true if NEXT_PUBLIC_DEV_MODE is set to "true" in environment variables
//////////////////////////////////////////////////////////////
export function devMode(): boolean {
  return process.env.NEXT_PUBLIC_DEV_MODE === 'true'
}

// Returns true if NEXT_PUBLIC_NO_AUTH_MODE is set to "true" in environment variables
//////////////////////////////////////////////////////////////
export function noAuthMode(): boolean {
  return process.env.NEXT_PUBLIC_NO_AUTH_MODE === 'true'
}
export function showWalletUI(): boolean {
  return process.env.NEXT_PUBLIC_WEB3_SHOW_WALLET_UI === 'true'
}

// Usage:
//   function newInst<T>(c: Constructor<T>): T
//   const newDbItem = newInst<DbItem>(DbItem)
//////////////////////////////////////////////////////////////
export type Constructor<T extends {} = {}> = new (...args: any[]) => T

// Returns true if each item of both arrays are equal
//////////////////////////////////////////////////////////////
export function arrayEquals(a: any, b: any) {
  return Array.isArray(a) && Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
}

// Expose globally the given object with the given name
//////////////////////////////////////////////////////////////
export const expose = (name: string, obj: any) => {
  if (isBrowser())
    (window as any)[name] = obj
}

/** Check if given stringOrArray should be considered as null
 * @returns {boolean} */
export const isNull = (stringOrArray: string | any[] | null | undefined, emptyIsNull: boolean = false): boolean => {
  return !stringOrArray || emptyIsNull && stringOrArray.length === 0
}

// Add the method 'capitalize' to string objects
//////////////////////////////////////////////////////////////
export const capitalize = (str: string) => !str ? str : str.charAt(0).toUpperCase() + str.slice(1)

// Return true if executed on browser
//////////////////////////////////////////////////////////////
export const isBrowser = () => typeof window !== 'undefined'

// Address cropping
//////////////////////////////////////////////////////////////
export const cropAddress = (address: any) => {
  const addr = String(address)
  if (!isHex(addr)) return addr
  return addr.substring(0, 5) + '...' + addr.substring(addr.length - 3)
}

// String obfuscation
//////////////////////////////////////////////////////////////
export const obfuscate = (str: string | null | undefined) => {
  if (typeof str !== 'string' || str.length < 6) return str
  return str.substring(0, 3) + '...' + str.substring(str.length - 3)
}

export const isValidEmail = (email: string) => {
  // instead of
  // import * as z from 'zod'
  // z.string().email().safeParse(email).success
  // as zod library seems to be to much restrictive (xxx@ventury.ey-avocats.com is rejected for instance)
  return email.match('^\\w+([\\.\\-\\+]?\\w+)*@\\w+([\\.\\-]?\\w+)*(\\.\\w{2,})+$')
}

// String parameter encoding
//////////////////////////////////////////////////////////////
export const isHex = (str: string) => {
  return str == null || str.match('0x[0-9a-fA-F]+')
}
export const encodeString = (str: string) => {
  if (isHex(str)) return str
  return ethers.keccak256(ethers.toUtf8Bytes(String(str)))
}
export const encodeStringBytes4 = (str: string) => {
  if (isHex(str)) return str
  str = encodeString(str)
  return str.substring(0, 10)
}

// Decimal number/string TO Hexadecimal string
//////////////////////////////////////////////////////////////
export const decToHex = (input: number | string) => {
  return '0x' + parseInt(input.toString(), 10).toString(16)
}

// Hexadecimal string TO Decimal string
//////////////////////////////////////////////////////////////
export const hexToDec = (input: string) => {
  return parseInt(input, 16).toString(10)
}

// Pause the execution synchronously
//////////////////////////////////////////////////////////////
export const pause = (delayInMs: number) => {
  console.log('Pause start (' + delayInMs + 'ms)')
  return new Promise<void>(async (resolve) => {
    setTimeout(() => {
      console.log('Pause end')
      resolve()
    }, delayInMs)
  })
}

// Start the chrono (Return a new timestamp)
//////////////////////////////////////////////////////////////
export const startChrono = () => {
  return Date.now().valueOf()
}

// Print time spent since given timestamp
//////////////////////////////////////////////////////////////
export const printChrono = (startTime: number, taskName: string, unit: 'ms' | 'sec' = 'ms', end: any = '') => {
  const diff: number = (new Date().valueOf() - startTime) / (unit === 'sec' ? 1000 : 1)
  console.log(taskName + ' in ' + (unit === 'sec' ? diff.toFixed(2) : diff) + unit, end)
  return startChrono()
}

// Browser Notifications
//////////////////////////////////////////////////////////////
export const notifyBrowser = async ({ title, options }: { title: string, options: NotificationOptions }): Promise<Notification | undefined> => {
  const sendNotification = () => new Notification(title, options)
  if (!('Notification' in window)) {
    alert('This browser does not support desktop notification')
    return undefined
  }
  if (Notification.permission === 'granted') {
    return sendNotification()
  }
  if (Notification.permission !== 'denied') {
    const permission = await Notification.requestPermission()
    if (permission === 'granted') {
      return sendNotification()
    }
  }
  return undefined
}

// TODO Comment
//////////////////////////////////////////////////////////////
export const toURI = (uri: string) => {
  if (uri.startsWith('ipfs://')) {
    return uri = 'https://gateway.ipfs.io/ipfs/' + uri.substring('ipfs://'.length)
  }
  return uri
}

// Precise Interval
//////////////////////////////////////////////////////////////
export const setPreciseInterval = (workFunc: () => void, interval: number, errorFunc?: () => void) => {
  let expected = Date.now() + interval
  let timeout = setTimeout(step, interval)

  function step() {
    const drift = Date.now() - expected
    if (drift > interval) {
      // You could have some default stuff here too...
      if (errorFunc) errorFunc()
    }
    workFunc()
    expected += interval
    timeout = setTimeout(step, Math.max(0, interval - drift))
  }

  return {
    stop: () => clearTimeout(timeout)
  }
}

export const DEFAULT_HYGRAPH_LANG = 'en'
export const toLocalesHygraph = (locale: string = DEFAULT_HYGRAPH_LANG) => {
  const locales = [locale]
  if(locale !== DEFAULT_HYGRAPH_LANG)
    locales.push(DEFAULT_HYGRAPH_LANG)
  return locales
}

const YOUTUBE_URL_EMBEDDED = 'https://www.youtube-nocookie.com/embed/'
const YOUTUBE_URL = 'https://www.youtube.com/watch?v='
export const buildYoutubeUrl = (id: string, embedded: boolean, timing?: string|number|null) => {
  return (embedded ? YOUTUBE_URL_EMBEDDED : YOUTUBE_URL) + id + (!timing || timing as number <= 0 ? '' : '&t='+timing+'s')
}

// Metadata alternates
//////////////////////////////////////////////////////////////
export const getMetadataAlternates = (uri: string, lang?: string) => {
  return {
    canonical: getLocalizedUri(uri, lang),
    languages: {
      'fr-FR': uri === '' ? '/' : uri,
      'en-US': '/en' + uri
    }
  }
}
// Build page Metadata
//////////////////////////////////////////////////////////////
export async function buildMetadataWebsite(
  title: string, description: string, pageUrl: string, imageUrl?: string|null, videoUrl?: string|null): Promise<Metadata> {
  const videos = !videoUrl ? {} : {videos: videoUrl}
  return {
    title: title,
    description: description,
    alternates: getMetadataAlternates(pageUrl),
    openGraph: {
      type: 'website',
      siteName: siteConfig.appName,
      title: title,
      description: description,
      images: imageUrl || siteConfig.logo.horizontalBjRatio2_1,
      ...videos
    }
  }
}
export async function buildMetadataArticle(post: any, pageUrl: string, lang: string): Promise<Metadata> {
  const updatedAt = new Date(post.updatedAt).toDateString()
  const createdAt = !post.createdAt ? updatedAt : new Date(post.createdAt).toDateString()
  return {
    title: post.metaTitle,
    description: post.metaDescription,
    alternates: getMetadataAlternates(pageUrl, lang),
    openGraph: {
      type: 'article',
      siteName: siteConfig.appName,
      title: post.metaTitle,
      description: post.metaDescription,
      authors: post.author?.name,
      publishedTime: createdAt,
      modifiedTime: updatedAt,
      images: post.featuredImage?.url || siteConfig.logo.horizontalBjRatio2_1
    }
  }
}